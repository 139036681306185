// For use with React-Icons

.icon--size-l {
  height: 1.2em;
  width: 1.2em;
}

.icon--size-xl {
  height: 1.6em;
  width: 1.6em;
}

.width-50-pixels {
  width: 50px;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.quote {
  border-left: 5px solid #dee2e6;
  padding-left: 0.5rem;
}

.warning-banner {
  position: sticky;
  background-color: #da3434;
  color: #fff;
  width: 100%;
  text-align: center;
  left: 0;
  top: 0;
  z-index: 10000;
}

.copy-reference { 
    color: "red" !important; 
    cursor: "pointer";

    &:hover
    {
        color: "#007bff";
        text-decoration: "underline";
    }
}