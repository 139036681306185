.copy-reference {
  color: #000000;
  cursor: pointer;

  &:hover {
    color: #007bff;

    svg {
      fill: #007bff; // Change the color of the SVG
    }
  }
}