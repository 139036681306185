.ql-snow .ql-tooltip::before {
  content: "URL:"; /* Change the label text */
  line-height: 26px;
  margin-right: 8px;
}

.ql-tooltip {
  left: 0px!important;
  top: 0px!important;
  transform: translateY(0px)!important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Legg til";
}


.custom-quill .ql-snow .ql-tooltip::before {
  content: "URL:";
}